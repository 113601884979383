<template>
  <div class="mainPage container promotion-details">
    <el-form
      :model="promotionForm"
      :rules="isCheck ? {} : rules"
      ref="promotionRef"
      label-width="100px"
      :disabled="isCheck"
      class="promotionForm-ruleForm"
    >
      <!-- 基本信息 -->
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>基本信息</div>
      </div>
      <el-form-item label="活动名称" prop="promotion_name">
        <el-input
          v-model="promotionForm.promotion_name"
          :disabled="isNow == 1"
          maxlength="20"
          style="width: 330px"
        ></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="promotion_type">
        <el-radio-group
          v-model="promotionForm.promotion_type"
          :disabled="isEdit"
        >
          <el-radio :label="1">满减</el-radio>
          <el-radio :label="2">每满减</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="优惠内容" prop="content">
        <!-- 满减 -->
        <template v-if="promotionForm.promotion_type == 1">
          <div
            v-for="(item, index) in promotionForm.promotion_content"
            :key="index"
            class="content1"
          >
            <span>满&nbsp;</span>
            <el-input-number
              v-model="item.money"
              :min="1"
              :max="99999"
              :precision="0"
              :controls="false"
              style="width: 100px"
              :disabled="isEdit"
            ></el-input-number>
            <span>&nbsp;元，减&nbsp;</span>
            <el-input-number
              v-model="item.enough"
              :min="1"
              :max="99999"
              :precision="0"
              :controls="false"
              style="width: 100px"
              :disabled="isEdit"
            ></el-input-number>
            <span>&nbsp;元</span>
            <el-button
              v-if="
                promotionForm.promotion_content.length - 1 == index &&
                  promotionForm.promotion_content.length <= 4
              "
              type="text"
              class="btn"
              :disabled="isEdit"
              @click="addContent"
              >添加阶梯</el-button
            >
            <el-button
              v-if="promotionForm.promotion_content.length > 1"
              type="text"
              class="btn"
              @click="delContent(index)"
              :disabled="isEdit"
              >删除</el-button
            >
          </div>
        </template>
        <!-- 每满减 -->
        <template v-if="promotionForm.promotion_type == 2">
          <span>每满&nbsp;</span>
          <el-input-number
            v-model="promotionForm.money"
            :min="1"
            :max="99999"
            :precision="0"
            :controls="false"
            style="width: 100px"
            :disabled="isEdit"
          ></el-input-number>
          <span>&nbsp;元，减&nbsp;</span>
          <el-input-number
            v-model="promotionForm.enough"
            :min="1"
            :max="99999"
            :precision="0"
            :controls="false"
            style="width: 100px"
            :disabled="isEdit"
          ></el-input-number>
          <span>&nbsp;元，最多优惠&nbsp;</span>
          <el-input-number
            v-model="promotionForm.max_enough"
            :min="1"
            :max="99999"
            :precision="0"
            :controls="false"
            style="width: 100px"
            :disabled="isEdit"
          ></el-input-number>
          <span>&nbsp;元</span>
        </template>
      </el-form-item>

      <el-form-item label="活动时间" prop="activityTime">
        <el-date-picker
          v-model="promotionForm.activityTime"
          type="datetimerange"
          align="right"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          style="width: 330px"
          :disabled="isNow == 1"
        >
        </el-date-picker>
      </el-form-item>

      <!-- 适用范围 -->
      <div class="scope-application">
        <div style="display: flex; align-items: center">
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>适用范围</div>
          </div>
        </div>
        <el-form-item
          label="开启部分商品使用"
          prop="open_goods"
          label-width="130px"
        >
          <el-radio-group
            v-model="promotionForm.open_goods"
            :disabled="promotionForm.coupon_type == 3"
            @change="clickitem('first')"
          >
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="开启不适用商品"
          prop="close_goods"
          label-width="130px"
        >
          <el-radio-group
            v-model="promotionForm.close_goods"
            :disabled="promotionForm.coupon_type == 3"
            @change="clickitem('second')"
          >
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <el-tabs
        v-model="activeName"
        v-if="promotionForm.open_goods !== 0 || promotionForm.close_goods !== 0"
      >
        <el-tab-pane label="适用商品" name="first">
          <!-- 选择商品 -->
          <div class="goods-box">
            <el-button
              type="primary"
              style="margin-bottom: 10px"
              @click="chooseGoods"
              :disabled="!promotionForm.open_goods"
              >选择商品</el-button
            >
            <div class="table-box">
              <el-table
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="120"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="medium"
                      @click="deleteGoods(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNum > 0"
              :total="totalNum"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>

        <el-tab-pane label="不适用商品" name="second">
          <!-- 选择商品 -->
          <div class="goods-box">
            <el-button
              type="primary"
              style="margin-bottom: 10px"
              @click="chooseGoods"
              :disabled="!promotionForm.close_goods"
              >选择商品</el-button
            >
            <div class="table-box">
              <el-table
                :data="tableCloseData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="120"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="medium"
                      @click="deleteGoods(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNumClose > 0"
              :total="totalNumClose"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="submit flex center align-center">
        <el-button @click="cancel" size="medium">取消</el-button>
        <el-button type="primary" @click="submit" size="medium">提交</el-button>
      </div>
    </el-form>

    <!-- 商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="goodsShow" width="800px">
      <div class="goods-content">
        <div class="searchBox">
          <el-input
            placeholder="商品关键字"
            v-model="goods_name"
            clearable
            style="margin-right: 6px"
          >
          </el-input>
          <el-button
            type="primary"
            style="margin-bottom: 10px"
            @click="getGoodsList"
            >查询</el-button
          >
        </div>
        <div class="table-box" style="border: none">
          <el-table
            :data="goodsData"
            tooltip-effect="dark"
            style="width: 100%"
            height="400"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="全部商品">
              <template slot-scope="scope">
                <div class="item">
                  <img :src="scope.row.picture_str" />
                  <div>
                    {{ scope.row.goods_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销量" prop="real_sales"> </el-table-column>
            <el-table-column label="库存" prop="stock"> </el-table-column>
          </el-table>
          <div class="table-text">已选：{{ goodsSelection.length }} 件商品</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goodsShow = false">取 消</el-button>
        <el-button type="primary" @click="confirmGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/components/Pagination/index";
import operation from "../../../api/operation";
import discounts from "../../../api/discounts";
import _ from "lodash";
export default {
  components: {
    Pagination,
  },
  data() {
    const validateContent = (rule, value, callback) => {
      if (this.promotionForm.promotion_type == 1) {
        let data = this.promotionForm.promotion_content;
        let isEmpty = data.every(
          (item) => item.money && item.enough && item.money > item.enough
        );
        let isCorrect = data.some((item, index) => {
          if (index > 0) {
            return (
              item.money <= data[index - 1].money ||
              item.enough <= data[index - 1].enough
            );
          } else {
            return false;
          }
        });
        if (!isEmpty) {
          callback(new Error("请填写完整,减金额必须小于满金额"));
        } else if (isCorrect) {
          callback(
            new Error(
              "满减促销最多支持五档的阶梯设置，每一档的满金额需高于上一档的满金额，每一档的减金额也需高于上一档的减金额"
            )
          );
        } else {
          callback();
        }
      } else {
        if (!this.promotionForm.money || !this.promotionForm.enough) {
          callback(new Error("请填写完整"));
        } else {
          callback();
        }
      }
    };
    const validateActivityTime = (rule, value, callback) => {
      console.log(value);
      if (!value || !value.length) {
        callback(new Error("请选择时间"));
      } else if (
        dayjs().valueOf() > dayjs(value[0]).valueOf() &&
        this.isNow == 0
      ) {
        console.log("this.isNow", this.isNow);
        callback(new Error("活动开始时间必须大于当前时间"));
      } else {
        callback();
      }
    };
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      promotionForm: {
        promotion_name: "",
        promotion_type: 1,
        money: undefined,
        enough: undefined,
        max_enough: undefined,
        activityTime: [],
        time_start: "",
        time_end: "",
        promotion_content: [
          {
            money: undefined,
            enough: undefined,
          },
        ],
        open_goods: 0,
        close_goods: 0,
        close_goods_list: [],
        goods_list: [],
      },
      rules: {
        promotion_name: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        activityTime: [
          {
            required: true,
            validator: validateActivityTime,
            trigger: "change",
          },
        ],
        promotion_type: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        content: [
          {
            required: true,
            validator: validateContent,
            trigger: "blur",
          },
        ],
      },
      activeName: "first",
      totalNum: 0,
      totalNumClose: 0,
      pagination: {
        page: 1,
        pageNumber: 10,
      },
      tableHeaders: [
        {
          label: "商品",
          prop: "goods_info",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "规格",
          prop: "sku_name",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "价格",
          prop: "price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "会员价",
          prop: "member_price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "库存",
          prop: "stock",
          width: "auto",
          align: "center",
          fixed: false,
        },
      ],
      tableData: [], //适用商品数据
      tableCloseData: [], //不适用商品数据
      groupData: [],
      groupCloseData: [],
      chooseGoodsData: [],
      chooseCloseGoodsData: [],
      goodsData: [],
      goodsShow: false,
      goods_name: "",
      goodsSelection: [],
    };
  },
  filters: {
    promotionTypeText(val) {
      switch (val) {
        case 1:
          return "满减";
        case 2:
          return "每满减";
      }
    },
    statusText(val) {
      switch (val) {
        case 2:
          return "禁用";
        case 4:
          return "开启";
      }
    },
    dateFormat(val) {
      return !val ? "" : dayjs.unix(val).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    // isNow 进行中
    isNow() {
      return this.$route.query.isNow;
    },
    // 1:新增 2:编辑
    isEdit() {
      return this.$route.query.isAdd == 2;
    },
    isCheck() {
      return this.$route.query.isCheck == 1;
    },
    // 复制
    isCopy() {
      return this.$route.query.isCopy == 1;
    },
    defaultImg() {
      return require("../../../assets/default_img.png");
    },
  },
  watch: {
    chooseGoodsData: {
      handler: function(n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    chooseCloseGoodsData: {
      handler: function(n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    "pagination.pageNumber": {
      handler: function(n, o) {
        this.sortData();
        this.groupGoods();
      },
    },
  },
  created() {
    if (this.isCheck || this.isEdit || this.isCopy) {
      this.getPromotionActivityInfo();
    }
  },
  methods: {
    // 获取详情
    getPromotionActivityInfo() {
      let params = {
        promotion_id: this.$route.query.promotion_id,
      };
      let loading = this.$loading();
      operation
        .getPromotionActivityInfo(params)
        .then((res) => {
          let { code, data } = res;
          if (code === 0) {
            this.promotionForm = _.omit(data.info, [
              "create_time",
              "shop_id",
              "status",
              "time_end",
              "time_start",
              "promotion_content_str",
            ]);
            let time_start = this.dayjs
              .unix(data.info["time_start"])
              .format("YYYY-MM-DD HH:mm");
            let time_end = this.dayjs
              .unix(data.info["time_end"])
              .format("YYYY-MM-DD HH:mm");
            this.$set(this.promotionForm, "activityTime", [
              time_start,
              time_end,
            ]);

            this.chooseGoodsData = data.info["goods_list"] || [];
            this.chooseCloseGoodsData = data.info["close_goods_list"] || [];
            this.sortData();
            this.groupGoods();
          }
          loading.close();
        })
        .catch(() => loading.close());
    },
    // 获取商品弹框列表
    getGoodsList() {
      let params = {
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      discounts.shopGoodsLists(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.goodsData = data.list;
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    //点击单选框切换tab
    clickitem(val) {
      if (val == "first" && this.promotionForm.open_goods == 1) {
        this.activeName = val;
      }
      if (val == "second" && this.promotionForm.close_goods == 1) {
        this.activeName = val;
      }
    },
    //提交表单
    submit() {
      if (this.loading) return;
      let check = false;
      this.$refs.promotionRef.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      this.loading = true;
      let params = Object.assign({}, this.promotionForm);
      params = _.omit(params, ["activityTime"]);
      let time = this.promotionForm.activityTime;
      params["time_start"] = this.dayjs(time[0]).format("YYYY-MM-DD HH:mm");
      params["time_end"] = this.dayjs(time[1]).format("YYYY-MM-DD HH:mm");

      params["goods_list"] = this.getGoodsData(this.chooseGoodsData).map(
        (item) => {
          let obj = {
            goods_id: item.goods_id,
            goods_name: item.goods_name,
            sku_id: !item.sku_id ? 0 : item.sku_id,
            sku_name: item.sku_name,
          };
          return obj;
        }
      );
      params["close_goods_list"] = this.getGoodsData(
        this.chooseCloseGoodsData
      ).map((item) => {
        let obj = {
          goods_id: item.goods_id,
          goods_name: item.goods_name,
          sku_id: !item.sku_id ? 0 : item.sku_id,
          sku_name: item.sku_name,
        };
        return obj;
      });
      params.promotion_content = JSON.stringify(params.promotion_content);

      let interfaceName = "createPromotionActivity";
      if (this.isEdit) {
        interfaceName = "editPromotionActivity";
        params.coupon_id = this.$route.query.coupon_id;
      } else {
        interfaceName = "createPromotionActivity";
      }
      operation[interfaceName](params)
        .then((res) => {
          let { code } = res;
          if (code === 0) {
            this.$message.success(`${!this.isEdit ? "添加" : "编辑"}成功`);
            this.loading = false;
            this.cancel();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 阶梯---start
    addContent() {
      let obj = {
        money: undefined,
        enough: undefined,
      };
      this.promotionForm.promotion_content.push(obj);
    },
    delContent(index) {
      this.promotionForm.promotion_content.splice(index, 1);
    },
    // 阶梯---end

    // 商品弹框 ---start
    chooseGoods() {
      this.goodsShow = true;
      this.getGoodsList();
    },
    // 获取选择商品列表
    getList() {
      let isNull = this.groupData[this.pagination.page - 1];
      if (!isNull) {
        this.tableData = this.groupData[this.pagination.page - 2];
      } else {
        this.tableData = this.groupData[this.pagination.page - 1];
      }
      let isNullclose = this.groupCloseData[this.pagination.page - 1];
      if (!isNullclose) {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 2];
      } else {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 1];
      }
    },
    // 选择商品
    handleSelectionChange(val) {
      this.goodsSelection = val;
    },
    // 商品分组
    groupGoods() {
      this.totalNum = this.chooseGoodsData.length;
      this.totalNumClose = this.chooseCloseGoodsData.length;
      this.groupData = _.chunk(
        this.getGoodsData(this.chooseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
      this.groupCloseData = _.chunk(
        this.getGoodsData(this.chooseCloseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
    },
    //去重
    getGoodsData(arr) {
      // 缓存用于记录
      const cache = [];
      for (const t of arr) {
        // 检查缓存中是否已经存在
        if (
          cache.find((c) => c.goods_id === t.goods_id && c.sku_id === t.sku_id)
        ) {
          // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
          continue;
        }
        // 不存在就说明以前没遇到过，把它记录下来
        cache.push(t);
      }
      // 记录结果就是过滤后的结果
      return cache;
    },
    // 数据排序
    sortData() {
      if (this.activeName == "first") {
        let list = JSON.parse(JSON.stringify(this.chooseGoodsData));
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              // list[i]['item'][j].seq = j + i;
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        this.chooseGoodsData = _.uniqBy(arr, "filter_id");
      } else if (this.activeName == "second") {
        let list = JSON.parse(JSON.stringify(this.chooseCloseGoodsData));
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        this.chooseCloseGoodsData = _.uniqBy(arr, "filter_id");
      }
    },
    // 确认商品
    confirmGoods() {
      this.goodsShow = false;
      if (this.activeName == "first") {
        if (!this.chooseGoodsData.length) {
          this.chooseGoodsData = this.goodsSelection;
        } else {
          this.chooseGoodsData.push(...this.goodsSelection);
        }
        this.sortData();
        this.groupGoods();
      } else if (this.activeName == "second") {
        if (!this.chooseCloseGoodsData.length) {
          this.chooseCloseGoodsData = this.goodsSelection;
        } else {
          this.chooseCloseGoodsData.push(...this.goodsSelection);
        }
        this.sortData();
        this.groupGoods();
      }
    },
    // 删除商品
    deleteGoods(index) {
      if (this.activeName == "first") {
        this.chooseGoodsData.splice(index, 1);
        this.sortData();
        this.groupGoods();
      } else if (this.activeName == "second") {
        this.chooseCloseGoodsData.splice(index, 1);
        this.sortData();
        this.groupGoods();
      }
    },
    // 商品弹框 ---end
  },
};
</script>
<style lang="scss" scoped>
.promotion-details {
  .el-form {
    .el-form-item {
      margin-bottom: 30px;
    }
    .content1 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .btn {
        margin-left: 20px;
      }
    }
  }
  .lineH-box {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #606266;
    font-weight: 700;
    margin-bottom: 20px;
    .lineH {
      width: 3px;
      height: 18px;
      background: #4974f5;
      border-radius: 21px;
      margin-right: 8px;
    }
  }
  .goods-box {
    .goods_info {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
  }
  .goods-content {
    .searchBox {
      display: flex;
      width: 400px;
    }
    .table-box {
      .el-table::before {
        height: 0;
      }
      .item {
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
