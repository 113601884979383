import axios from "../service/axios";
export default {
  // 获取活动列表
  getActivityLists(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/getActivityLists",
      data,
    });
  },
  // 修改活动状态
  editActivityStatus(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/editActivityStatus",
      data,
    });
  },
  // 新增、编辑活动
  creatShopActivity(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/creatShopActivity",
      data,
    });
  },
  // 获取活动详情
  getActivityInfo(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/getActivityInfo",
      data,
    });
  },
  // 获取活动统计数据
  getActivityStatisticalData(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/getActivityStatisticalData",
      data,
    });
  },
  // 获取活动折线图数据
  getActivityLineStatisticalData(data) {
    return axios({
      method: "post",
      url: "/store/shopactivity/getActivityLineStatisticalData",
      data,
    });
  },
  // 获取促销活动列表
  getPromotionActivityLists(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/getPromotionActivityLists",
      data,
    });
  },
  // 创建促销活动
  createPromotionActivity(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/createPromotionActivity",
      data,
    });
  },
  // 编辑促销活动
  editPromotionActivity(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/editPromotionActivity",
      data,
    });
  },
  // 获取促销活动详情
  getPromotionActivityInfo(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/getPromotionActivityInfo",
      data,
    });
  },
  // 编辑促销活动状态
  savePromotionStatus(data) {
    return axios({
      method: "post",
      url: "/store/Shopactivity/savePromotionStatus",
      data,
    });
  },
};
